// Store
import {setLoading} from './generalActions';
import { getAllPositions } from './positionActions';
import subscriptionServices from '../../../api/subscriptionServices';

// Types
import {AppDispatch} from '../../store';
import {SubscriptionType} from '../../../types/subscriptionTypes';
import {subscriptionTypes, positionTypes} from '../types';
import {GetPositionsType} from '../../../types/positionTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


// #region GET

export const getAllPositionsSubscribed = (data: GetPositionsType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  subscriptionServices.getAllPositionsSubscribed(data)
    .then(response => {
      dispatch({
        type: positionTypes.GET_ALL_POSITIONS,
        positionList: response.data.rows
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getAllByPositionId = (positionId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  subscriptionServices.getAllByPositionId(positionId)
    .then(response => {
      dispatch({
        type: subscriptionTypes.GET_SUBSCRIPTIONS_BY_POSITION_ID,
        subscriptionList: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion

// #region POST

export const createAll = (data: SubscriptionType[]) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  subscriptionServices.createAll(data)
    .then(response => {
      SUCCESS_MODAL(response.data.status);
      dispatch(setLoading(false));
      dispatch(getAllPositions({ page: 1, size: 5 }));
      dispatch({
        type: subscriptionTypes.GET_SUBSCRIPTIONS_BY_POSITION_ID,
        subscriptionList: []
      });
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion

// #region POST

export const deleteSubscription = (positionId: string, onlySubscriptions: boolean) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  subscriptionServices.deleteSubscription(positionId)
    .then(response => {
      SUCCESS_MODAL(response.data.status);
      dispatch({
        type: subscriptionTypes.GET_SUBSCRIPTIONS_BY_POSITION_ID,
        subscriptionList: []
      });
      onlySubscriptions
        ? dispatch(getAllPositionsSubscribed({ page: 1, size: 5 }))
        : dispatch(getAllPositions({ page: 1, size: 5 }));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}

// #endregion