// Libraries
import React, {FunctionComponent, useState} from 'react';
import {Col, List, Row, Skeleton, Space, Switch, Tag} from 'antd';
import {
  SolutionOutlined, LaptopOutlined, TeamOutlined, EyeOutlined, EyeInvisibleOutlined,
  GlobalOutlined, LockOutlined, DollarOutlined, StarOutlined, EnvironmentOutlined, EditFilled
} from '@ant-design/icons';
import PropTypes from 'prop-types';

// Store
import {useSelector} from 'react-redux';
import * as positionSelectors from '../../store/redux/selectors/positionSelectors';
import {useAppDispatch} from '../../store/store';
import {updatePosition} from '../../store/redux/actions/positionActions';

// Components
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import AddEditSubscription from './subscriptions/AddEditSubscription';

// Types
import {PositionType} from '../../types/positionTypes';

// Functions and constants
import {currencyFormat, redirectWindow, userIsAdmin} from '../../utils/functions';
import {routes} from '../../utils/routes';


type PositionListType = {
  onlySubscriptions: boolean;
}
const PositionsList: FunctionComponent<PositionListType> = (
  {
    onlySubscriptions
  }) => {
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(false);
  const [positionId, setPositionId] = useState('');
  const [isEditingSubscription, setEditingSubscription] = useState(false);
  const dispatch = useAppDispatch();
  const positionList = useSelector(positionSelectors.allPositionsSelector);
  const positionParams = useSelector(positionSelectors.positionParamsSelector);
  const teams = positionParams!.teamOptions;
  const places = positionParams!.placeOptions;
  const contracts = positionParams!.contractTypes;

  const handleRedirectDetail = (positionId: string) => {
    redirectWindow(`${routes.positions.detail}?idVacante=${positionId}&subscription=${onlySubscriptions}`);
  };

  const handleAddEditModalVisible = (visible: boolean, isEditing: boolean, positionId: string) => {
    setPositionId(positionId);
    setEditingSubscription(isEditing);
    setSubscriptionModalVisible(visible);
  };

  const handlePositionAvailability = (position: PositionType, isInternal: boolean) => {
    const newPosition = JSON.parse(JSON.stringify(position));
    newPosition.internal = isInternal;
    dispatch(updatePosition(newPosition))
  };

  const handlePositionStatus = (position: PositionType, isActive: boolean) => {
    const newPosition = JSON.parse(JSON.stringify(position));
    newPosition.active = isActive;
    dispatch(updatePosition(newPosition))
  };

  return (
    <>
      <List dataSource={positionList}
            renderItem={(position) => (
              <List.Item key={position.idPosition} className={'px-4 py-4 sm:px-6 [&>ul]:h-fit'}>
                <Skeleton active loading={false}>
                  <List.Item.Meta
                    title={
                      <Row>
                        <Col md={12} xs={24} className={'flex'}>
                          <Space size={'small'}>
                            <TitleComponent level={5} text={position.title}
                                            className={'my-auto text-blue-dkt-300 font-medium hover:underline cursor-pointer'}
                                            onClick={() => handleRedirectDetail(position.idPosition ?? '')} />
                            {onlySubscriptions && <span className={'hidden md:block'}>&bull;</span>}
                            {onlySubscriptions &&
                              <Tag title={'Candidatos'}
                                  className={'mr-0 bg-white border-0 text-orange-dkt-400 text-center text-base'}>
                                <TeamOutlined title={'Candidatos'}/> - {position.applicationsNumber}
                              </Tag>
                            }
                          </Space>
                        </Col>
                        <Col md={12} xs={24} className={'text-center md:text-right mt-2'}>
                          <Space size={'small'} wrap>
                            {position.isSubscribed
                              ?
                              <ButtonComponent text={'Suscrito'} icon={<EditFilled/>}
                                               className={'text-blue-dkt-500 text-xs border-blue-dkt-400 border leading-4 ' +
                                                 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-300 ' +
                                                 'after:content-none'}
                                               onClick={() => handleAddEditModalVisible(true, true, position.idPosition!)}/>
                              :
                              <ButtonComponent text={'Suscribirme'} icon={<StarOutlined/>}
                                               className={'text-grey-dkt-700 text-xs bg-white border border-grey-dkt-300 leading-4 ' +
                                                 'hover:bg-grey-dkt-50 focus:outline-none'}
                                               onClick={() => handleAddEditModalVisible(true, false, position.idPosition!)}/>
                            }

                            {userIsAdmin() &&
                              < Switch defaultChecked={position.internal}
                                       onChange={(isInternal) => handlePositionAvailability(position, isInternal)}
                                       checkedChildren={<span><LockOutlined /> Interna</span>}
                                       unCheckedChildren={<span><GlobalOutlined /> Externa</span>}
                                       className={'w-24 same-style'} />
                            }

                            {userIsAdmin() &&
                              <Switch defaultChecked={position.active}
                                      onChange={(isActive) => handlePositionStatus(position, isActive)}
                                      checkedChildren={<span><EyeOutlined/> Activa</span>}
                                      unCheckedChildren={<span><EyeInvisibleOutlined/> Inactiva</span>}
                                      className={'w-24'}/>
                            }
                          </Space>
                        </Col>
                      </Row>
                    }
                    description={
                      <Row>
                        <Col md={24} xs={24} className={'mt-2'}>
                          <Space size={'small'} wrap
                                 className={'w-full'}>
                            <div className={'[&>span]:text-grey-dkt-500'}>
                              <LaptopOutlined title={'Cargo'} className={'mr-2'} />
                              <span>{teams.find((param) => param.id === position.idTeam)?.value ?? '-'}</span>
                            </div>
                            &nbsp;&bull;&nbsp;
                            {onlySubscriptions
                              ?
                              <div className={'[&>span]:text-grey-dkt-500'}>
                                <EnvironmentOutlined title={'Tienda'} className={'mr-2'}/>
                                <span>{places.find((param) => param.id === position.idContract)?.value ?? '-'}</span>
                              </div>
                              :
                              <div className={'[&>span]:text-grey-dkt-500'}>
                                <SolutionOutlined title={'Tipo de contrato'} className={'mr-2'}/>
                                <span>{contracts.find((param) => param.id === position.idContract)?.value ?? '-'}</span>
                              </div>
                            }
                            &nbsp;&bull;&nbsp;
                            <div className={'[&>span]:text-grey-dkt-500'}>
                              <DollarOutlined title={'Salario'} className={'mr-2'} />
                              <span>{`$ ${currencyFormat(position.salary)}`}</span>
                            </div>
                          </Space>
                        </Col>
                      </Row>
                    }
                  />
                </Skeleton>
              </List.Item>
            )}
      />

      {subscriptionModalVisible &&
        <AddEditSubscription isEditing={isEditingSubscription} positionId={positionId}
                             onlySubscriptions={onlySubscriptions} 
                             onToggleModal={handleAddEditModalVisible} />
      }
    </>
  );
}

PositionsList.propTypes = {
  onlySubscriptions: PropTypes.bool.isRequired,
};
PositionsList.defaultProps = {
  onlySubscriptions: false,
};

export default PositionsList;