import React, { useEffect, useState } from 'react';
import {Row, Col, Input} from 'antd';
import {useAppDispatch} from '../../store/store';
import {RedoOutlined, SafetyOutlined} from '@ant-design/icons';

// Types
import {UserType} from '../../types/userTypes';

// Store
import {getUserParams} from '../../store/redux/actions/userActions';

// Components and Functions
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import UserList from './UserList';
import AccessControlModal from './subComponents/AccessControlModal';

// Constants
const {Search} = Input;


const Access = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    textFilter: ''
  });
  const [accessControlModalVisible, setAccessControlModalVisible] = useState(false);
  const [userToEdit, setUserToEdit] = useState<UserType | undefined>(undefined)
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserParams(pagination));
  }, [pagination]);

  const handleAccessControlModalVisible = (visible: boolean, userToEdit?: UserType) => {
    setAccessControlModalVisible(visible);
    setUserToEdit(userToEdit);
  }
  const handleSearchByText = (text: string) => {
    setPagination({ ...pagination, textFilter: text });
  };
  const handleLoadMore = () => {
    setPagination({...pagination, size: pagination.size + 10 });
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3} text={'Usuarios'} className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'contents [&>div]:my-2'}>
        <Col lg={12} md={16} xs={24}>
          <Search placeholder={'Filtrar por correo'}
                  className={'[&>span]:h-9 [&>span>input]:h-9 [&>span>span>button]:h-9 ' +
                    '[&>span>input:focus]:outline-none [&>span>input:focus]:ring-orange-dkt-400 [&>span>input:focus]:border-orange-dkt-400 ' +
                    '[&>span>input]:border [&>span>input]:border-grey-dkt-300 [&>span>input]:rounded-l-md [&>span>input]:shadow-sm ' +
                    '[&>span>input]:placeholder-grey-dkt-400 [&>span>span>button]:rounded-r-md'}
                  onChange={({target}) => target.value.length === 0 && handleSearchByText('')}
                  onSearch={handleSearchByText} enterButton />
        </Col>
        <Col lg={12} md={8} xs={24} className={'text-right'}>
          <ButtonComponent text={'Dar acceso'}
                           icon={<SafetyOutlined />}
                           className={'w-fit bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                             'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                           onClick={() => handleAccessControlModalVisible(true)}/>
        </Col>
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <UserList onToggleModal={handleAccessControlModalVisible} />
      </Col>

      <Col md={24} xs={24} className={'text-center pt-5'}>
        <ButtonComponent text={'Ver más usuarios'} icon={<RedoOutlined />}
                         className={'w-60 h-9 justify-center border border-transparent rounded-md ' +
                           'shadow-sm text-sm font-medium text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300'}
                         onClick={handleLoadMore} />
      </Col>

      {accessControlModalVisible &&
        <AccessControlModal userToEdit={userToEdit} onToggleModal={handleAccessControlModalVisible} />
      }
    </Row>
  );
}

export default Access;