import React, {FunctionComponent, useEffect} from 'react';
import {Row, Col, List, Tooltip} from 'antd';
import {CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';

// Store
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../../../store/store';
import {getAllFormations, getFormationById} from '../../../store/redux/actions/settingActions';
import * as settingSelectors from '../../../store/redux/selectors/settingSelectors';

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';

// Functions
import {screenSizeValidator} from '../../../utils/functions';


type FormationsType = {
  onToggleModal: (visible: boolean, isEditing: boolean, section: string, title: string) => void;
};
const Formations: FunctionComponent<FormationsType> = (
  {
    onToggleModal
  }) => {
  const dispatch = useAppDispatch();
  const formations = useSelector(settingSelectors.allFormationsSelector);
  const isSmallScreen = screenSizeValidator('(max-width: 930px)');

  useEffect(() => {
    dispatch(getAllFormations());
  }, []);

  const handleEditModal = (formationId: string) => {
    dispatch(getFormationById(formationId));
    onToggleModal(true, true, 'formations', 'Nivel de estudio');
  }

  return (
    <Row>
      <Col md={24} xs={24} className={'text-right mb-4'}>
        <ButtonComponent text={'Nuevo nivel de estudio'}
                         icon={<PlusOutlined />}
                         className={'w-fit bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                         onClick={() => onToggleModal(true, false, 'formations', 'Nivel de estudio')} />
      </Col>

      <Col md={24} xs={24}>
        <List itemLayout={'horizontal'} dataSource={formations}
              grid={{ gutter: 16, column: isSmallScreen ? 1 : 2 }}
              renderItem={item => (
                <List.Item key={item.idFormation}>
                  <Col md={24} xs={24} className={'flex'}>
                    <Col md={20} xs={12}>
                      <p className={'font-medium'}>{item.name}</p>
                      <p className={item.isActive ? 'text-green-dkt-500' : 'text-red-dkt-500'}>
                        {item.isActive
                          ? <CheckOutlined title={'Estado'} className={'mr-2'} />
                          : <CloseOutlined title={'Estado'} className={'mr-2'} />
                        }
                        {item.isActive ? 'Activo' : 'Inactivo'}
                      </p>
                    </Col>
                    <Col md={4} xs={12} className={'text-right flex'}>
                      <Tooltip title={'Editar'}>
                        <EditOutlined className={'my-auto ml-auto mr-0 text-blue-dkt-400'}
                                      onClick={() => handleEditModal(item.idFormation!)} />
                      </Tooltip>
                    </Col>
                  </Col>
                </List.Item>
              )}
        />
      </Col>
    </Row>
  );
}

Formations.propTypes = {
  onToggleModal: PropTypes.func.isRequired,
};
Formations.defaultProps = {
  onToggleModal: () => {
    return null;
  }
};
export default Formations;