import React, {useEffect} from 'react';
import ReactQuill from 'react-quill';
import {Row, Col, Form} from 'antd';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../../store/store';
import {getPositionContent, setPositionContent} from '../../../store/redux/actions/contentActions';
import * as contentSelectors from '../../../store/redux/selectors/contentSelectors';

// Components
import FormItemComponent from '../../subComponents/FormItemComponent';
import TitleComponent from '../../subComponents/TitleComponent';
import ButtonComponent from '../../subComponents/ButtonComponent';

// Types
import {PositionContentType} from '../../../types/contentTypes';


const PositionContent = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const positionContent = useSelector(contentSelectors.positionSelector);

  useEffect(() => {
    dispatch(getPositionContent());
  }, []);

  useEffect(() => {
    form.setFieldsValue(positionContent)
  }, [positionContent]);
  
  const onSubmit = (values: PositionContentType) => {
    dispatch(setPositionContent(values));
  };
  
  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Row gutter={16}>
        <Col md={24} xs={24} className={'mt-4 mb-2'}>
          <TitleComponent level={4} text={'General'} className={'mb-1 leading-6 font-medium text-blue-dkt-400'} />
        </Col>
        <Col md={24} xs={24}>
          <FormItemComponent name={'positionPresentation'} label={'Presentación de vacantes'} required={true}
                            child={<ReactQuill theme="snow"/>} />
        </Col>
        <Col md={24} xs={24} className={'text-center mt-4'}>
          <ButtonComponent text={'Guardar'} htmlType={'submit'}
                          className={'w-60 h-9 bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                            'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'} />
        </Col>
      </Row>
    </Form>
  );
}

export default PositionContent;