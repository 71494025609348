import React, {useEffect} from 'react';
import {useAppDispatch} from '../../store/store';
import {Row, Col, Space, Card} from 'antd';
import {BarsOutlined, PlusOutlined, LaptopOutlined, TeamOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import * as dashboardSelectors from '../../store/redux/selectors/dashboardSelectors';
import * as userSelectors from '../../store/redux/selectors/userSelectors';
import {getDashboard} from '../../store/redux/actions/dashboardActions';
import {getUserInfo} from '../../store/redux/actions/userActions';
import {noteType} from '../../types/userTypes';

// Components
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import {BestApplicantIcon, PostIcon, ReviewIcon} from '../../assets/customIcons';

// Constants and Functions
import {getLocalStorageItem, redirectWindow, userIsAdmin} from '../../utils/functions';
import {routes} from '../../utils/routes';
import {hoverableCardClassName, hoverableCardContentClassName} from '../../utils/componentsUtils';


const Dashboard = () => {
  const dispatch = useAppDispatch();
  const dashboardInfo = useSelector(dashboardSelectors.dashboardInfoSelector);
  const userInfo = useSelector(userSelectors.userInfoSelector);
  const notesInLocalStorage = getLocalStorageItem('notes');
  const userName = userInfo?.userName ?? '';

  useEffect(() => {
    dispatch(getDashboard());
  }, []);

  useEffect(() => {
    const notes: noteType = {
      notes: notesInLocalStorage ?? ''
    } 

    dispatch(getUserInfo(notes));
  }, []);
  
  const handleRedirectNewPosition = () => {
    redirectWindow(routes.positions.new);
  };

  return (
    <Row className={'-mt-6 -mx-8'}>
      <header className="w-full h-32 pb-24 bg-grey-dkt-800 rounded-b-lg -ml-16 translate-x-16" />

      <Col className="w-full py-6 px-4 sm:px-6 md:px-8">
        <Col md={24} xs={24} className="-mt-24 z-10 p-8 bg-grey-dkt-50 shadow rounded-lg md:flex">
          <Col md={12} xs={24}>
            <TitleComponent level={1} text={`¡Hola ${userName}!`}
                            className={'text-xl lg:text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate'} />
            <Space size={'middle'}>
              <div className={'text-sm text-gray-500 font-medium capitalize [&>span]:text-grey-dkt-500'}>
                <BarsOutlined title={'Tienda'} /> <span>{dashboardInfo.site}</span>
              </div>
              <div className={'text-sm text-gray-500 font-medium capitalize [&>span]:text-grey-dkt-500'}>
                <LaptopOutlined title={'Cargo'} /> <span>{dashboardInfo.position}</span>
              </div>
            </Space>
          </Col>
          {userIsAdmin() && 
            <Col md={12} xs={24} className={'w-full md:text-right mt-4 md:m-auto'}>
              <ButtonComponent text={'Nueva vacante'} icon={<PlusOutlined />}
                              className={'w-fit right bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick={handleRedirectNewPosition} />
            </Col>
          }
        </Col>

        <Col md={24} xs={24} className={'grid my-6 lg:grid-cols-3 gap-4'}>
          <Col className={'p-4 flex flex-col h-28 lg:h-48 rounded-xl text-white justify-start lg:justify-end shadow-lg bg-gradient-to-tr ' +
            'from-blue-dkt-400 to-blue-dkt-600'}>
            <PostIcon width={50} height={50} fill={'white'} className={'absolute top-4 right-4'} />
            <p className="font-bold text-2xl sm:text-3xl">Publica</p>
            <p className="font-bold text-lg sm:text-xl">una vacante</p>
          </Col>
          <Col className={'p-4 flex flex-col h-28 lg:h-48 rounded-xl text-white justify-start lg:justify-end shadow-lg bg-gradient-to-tr ' +
            'from-blue-dkt-400 to-blue-dkt-600'}>
            <ReviewIcon width={50} height={50} fill={'white'} className={'absolute top-4 right-4'} />
            <p className="font-bold text-2xl sm:text-3xl">Revisa</p>
            <p className="font-bold text-lg sm:text-xl">tus candidatos</p>
          </Col>
          <Col className={'p-4 flex flex-col h-28 lg:h-48 rounded-xl text-white justify-start lg:justify-end shadow-lg bg-gradient-to-tr ' +
            'from-blue-dkt-400 to-blue-dkt-600'}>
            <BestApplicantIcon width={55} height={55} fill={'white'} className={'absolute top-4 right-4'} />
            <p className="font-bold text-2xl sm:text-3xl">Encuentra</p>
            <p className="font-bold text-lg sm:text-xl">el candidato perfecto</p>
          </Col>
        </Col>

        <Col md={24} xs={24} className={'mt-4'}>
          <TitleComponent level={4} text={'Estadísticas'} className={'font-semibold text-gray-900'} />

          <Col className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <Card hoverable={true} className={hoverableCardClassName()}>
              <Row className={hoverableCardContentClassName()}>
                <Col md={6} xs={6} className={'flex'}>
                  <TeamOutlined className={'my-auto [&>svg]:w-8 [&>svg]:h-8'} />
                </Col>
                <Col md={18} xs={18}>
                  <p className="text-md font-medium text-gray-500 truncate">Ofertas publicadas</p>
                  <dd>
                    <div className="text-lg text-gray-900">{dashboardInfo.positionsCount}</div>
                  </dd>
                </Col>
              </Row>
            </Card>
            <Card hoverable={true} className={hoverableCardClassName()}>
              <Row className={hoverableCardContentClassName()}>
                <Col md={6} xs={6} className={'flex'}>
                  <TeamOutlined className={'my-auto [&>svg]:w-8 [&>svg]:h-8'} />
                </Col>
                <Col md={18} xs={18}>
                  <p className="text-md font-medium text-gray-500 truncate">Ofertas disponibles</p>
                  <dd>
                    <div className="text-lg text-gray-900">{dashboardInfo.activePositionsCount}</div>
                  </dd>
                </Col>
              </Row>
            </Card>
            <Card hoverable={true} className={hoverableCardClassName()}>
              <Row className={hoverableCardContentClassName()}>
                <Col md={6} xs={6} className={'flex'}>
                  <TeamOutlined className={'my-auto [&>svg]:w-8 [&>svg]:h-8'} />
                </Col>
                <Col md={18} xs={18}>
                  <p className="text-md font-medium text-gray-500 truncate">Aplicantes</p>
                  <dd>
                    <div className="text-lg text-gray-900">{dashboardInfo.applicantsCount}</div>
                  </dd>
                </Col>
              </Row>
            </Card>
            <Card hoverable={true} className={hoverableCardClassName()}>
              <Row className={hoverableCardContentClassName()}>
                <Col md={6} xs={6} className={'flex'}>
                  <TeamOutlined className={'my-auto [&>svg]:w-8 [&>svg]:h-8'} />
                </Col>
                <Col md={18} xs={18}>
                  <p className="text-md font-medium text-gray-500 truncate">Candidaturas</p>
                  <dd>
                    <div className="text-lg text-gray-900">{dashboardInfo.applicationsCount}</div>
                  </dd>
                </Col>
              </Row>
            </Card>
          </Col>
        </Col>
      </Col>
    </Row>
  );
}

export default Dashboard;