// Libraries
import React, {FunctionComponent} from 'react';
import {Checkbox, Col, Row, Form} from 'antd';
import PropTypes from 'prop-types';

// Types
import {GetPositionsType} from '../../../types/positionTypes';

// Store
import {useSelector} from 'react-redux';
import * as positionSelectors from '../../../store/redux/selectors/positionSelectors';

// Components and Subcomponents
import SelectComponent from '../../subComponents/SelectComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';

// Functions and Constants
import { userIsAdmin } from '../../../utils/functions';


type PositionFiltersType = {
  onlySubscriptions: boolean;
  onChangeSubscriptions: (checked: boolean) => void;
  onChangeFilters: (filters: GetPositionsType) => void;
}
const PositionFilters: FunctionComponent<PositionFiltersType> = (
  {
    onlySubscriptions, onChangeSubscriptions, onChangeFilters
  }) => {
  const [form] = Form.useForm();
  const positionParams = useSelector(positionSelectors.positionParamsSelector);
  const filterVisible = onlySubscriptions && userIsAdmin();

  const onSubmit = (values: GetPositionsType) => {
    onChangeFilters(values);
  };

  return (
    <Row gutter={[8, 8]}>
      {filterVisible &&
        <Col lg={16} md={14} xs={24}>
          <Form form={form} layout="vertical" autoComplete="off" onFinish={onSubmit}>
            <Col lg={12} md={16} xs={24} className={'w-full inline-block'}>
              <FormItemComponent name={'idTeam'} label={'Equipo'} required={false}
                                 child={
                                   <SelectComponent allowClear={true}
                                                    className={'w-full'} list={positionParams!.teamOptions}
                                                    onChange={() => form.submit()} />
                                 } />
            </Col>
          </Form>
        </Col>
      }
      <Col lg={filterVisible ? 8 : 24} md={filterVisible ? 10 : 24} xs={24} 
          className={'text-right my-auto'}>
        <Checkbox className={'h-full'} onChange={({target}) => onChangeSubscriptions(target.checked)}>
          Únicamente suscripciones
        </Checkbox>
      </Col>
    </Row>
  );
}

PositionFilters.propTypes = {
  onlySubscriptions: PropTypes.bool.isRequired,
  onChangeSubscriptions: PropTypes.func.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
};
PositionFilters.defaultProps = {
  onlySubscriptions: false,
  onChangeSubscriptions: () => {
    return;
  },
  onChangeFilters: () => {
    return;
  }
};

export default PositionFilters;