// Libraries
import React from 'react';
import {useSelector} from 'react-redux';
import {Col, Space, Steps, Empty, Tooltip, Form} from 'antd';
import {SaveOutlined} from '@ant-design/icons'

// Store
import * as  applicationsSelector from '../../../../store/redux/selectors/applicationSelectors';
import * as positionSelectors from '../../../../store/redux/selectors/positionSelectors';
import {useAppDispatch} from '../../../../store/store';
import {createApplicationEvent} from '../../../../store/redux/actions/eventApplicationActions';

// Types
import {ApplicationEventsType} from '../../../../types/applicationsTypes';

// Components and Subcomponents
import FormItemComponent from '../../../subComponents/FormItemComponent';
import SelectComponent from '../../../subComponents/SelectComponent';
import InputComponent from '../../../subComponents/InputComponent';
import ButtonComponent from '../../../subComponents/ButtonComponent';
import TitleComponent from '../../../subComponents/TitleComponent';

// Functions and Constants
import {getDateDifference} from '../../../../utils/functions';
const { Step } = Steps;


const ApplicationInfo = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const applicationDetail = useSelector(applicationsSelector.applicationsDetailSelector);
  const applicationParams = useSelector(applicationsSelector.applicationParamsSelector);
  const positionParams = useSelector(positionSelectors.positionParamsSelector);
  const applicant = applicationDetail.application.applicant;
  const events = applicationDetail.events;
  const timeStamp = applicationDetail.application.timeStamp;

  const handleSubmit = (values: ApplicationEventsType) => {
    values.idApplications = applicationDetail.application.idApplications ?? '';

    dispatch(createApplicationEvent(values, values.idApplications));
    form.resetFields();
  }

  const handleChangeEventSelect = (value: string) => {
    const event = applicationParams.eventOptions.find(event => event.id === value);
    form.setFieldValue('message', event?.message);
  }

  return (
    <Col className={'mb-2 bg-white p-4 rounded'}>
      <Col md={24} xs={24} className={'[&>div]:px-1.5 [&>div]:inline-block [&>div]:w-full'}>
        <Col md={24} xs={24} className={'mb-3'}>
          <TitleComponent level={5} text={'Postulación:'} className={'text-sm font-medium text-grey-dkt-400'} />
          <p>{timeStamp ? `Se postuló hace ${getDateDifference(timeStamp)}` : ''}</p>
        </Col>
        <Col md={24} xs={24} className={'mb-3'}>
          <TitleComponent level={5} text={'Lugar:'} className={'text-sm font-medium text-grey-dkt-400'} />
          <p>
            {positionParams?.placeOptions.find(placeOption => 
              placeOption.id === applicationDetail.application.placePosition?.idPlace)?.value
            }
          </p>
        </Col>
        {applicant?.exEmployePlace && 
          <Col md={24} xs={24} className={'mb-3'}>
            <TitleComponent level={5} text={'Trabajó en:'} className={'text-sm font-medium text-grey-dkt-400'} />
            <p>
              {applicant.exEmployePlace}
            </p>
          </Col>
        }
        <Col md={24} xs={24} className={'mb-3'}>
          <TitleComponent level={5} text={'Presentación:'} className={'text-sm font-medium text-grey-dkt-400'} />
          <p>{applicant!.biography}</p>
        </Col>
        <Col md={24} xs={24} className={'mb-3'}>
          <TitleComponent level={5} text={'Historial:'} className={'text-sm font-medium text-grey-dkt-400'} />
          {events.length > 0 ?
            <Steps progressDot direction="vertical" current={events.length - 1} size={'small'}
                    className={'mt-4'}>
              {events.map(event =>                                   
                <Step active={false} key={event.idEventsApplications} title={event.event?.name} 
                      disabled className={'[&>div]:cursor-auto'}
                      description={
                        <Tooltip title={event.event?.description}>{event.comment}</Tooltip>
                      }
                />
              )}
            </Steps>
          :
            <Empty description={'Sin eventos'}/>
          }
        </Col>
        <Col md={24} xs={24}>
          <Form form={form} layout="vertical" autoComplete="off" onFinish={handleSubmit}>
            <Space size={'small'} direction={'vertical'} className={'w-full'}>
              <FormItemComponent name={'idEvent'} label={'Evento'} required={true} className={'mb-0'}
                                child={
                                    <SelectComponent list={applicationParams.eventOptions}
                                                     onChange={handleChangeEventSelect}/>
                                } />
              <FormItemComponent name={'comment'} required={true} className={'mb-0'}
                                child={                                 
                                    <InputComponent type={'textarea'} placeHolder={'Comentarios'} />
                                } />
              <FormItemComponent name={'message'} required={true} className={'mb-0'}
                                child={                                 
                                    <InputComponent type={'textarea'} placeHolder={'Mensaje para el candidato'} />
                                } />
              <ButtonComponent text={'Actualizar'} icon={<SaveOutlined />} htmlType={'submit'}
                              className={'float-right bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none ' +
                                'focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'} />
            </Space>
          </Form>
        </Col>
      </Col>
    </Col>
  );
}

export default ApplicationInfo;