import React, {FunctionComponent, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Form, Space} from 'antd';
import {DeleteOutlined, ReloadOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import ReactQuill from 'react-quill';

// Store
import {useAppDispatch} from '../../../store/store';
import {addPositionImage, getPositionParams, updatePosition} from '../../../store/redux/actions/positionActions';
import * as positionSelectors from '../../../store/redux/selectors/positionSelectors';

// Components and Subcomponents
import TitleComponent from '../../subComponents/TitleComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';
import SelectComponent from '../../subComponents/SelectComponent';
import ButtonComponent from '../../subComponents/ButtonComponent';
import UploadComponent from '../../subComponents/UploadComponent';
import {ERROR_MODAL} from '../../subComponents/responseModals';

// Types
import {PositionType} from '../../../types/positionTypes';

// Functions and Constants
import {isEmpty, redirectWindow} from '../../../utils/functions';
import {routes} from '../../../utils/routes';


type PositionFormType = {
  isEditing: boolean;
  positionDetail?: PositionType | null;
}
const PositionForm: FunctionComponent<PositionFormType> = (
  {
    isEditing, positionDetail
  }) => {
  const [file, setFile] = useState({ name: '' });
  const [positionVideo, setPositionVideo] = useState('');
  const [formKey, setFormKey] = useState(0);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const positionParams = useSelector(positionSelectors.positionParamsSelector);
  const formationList = positionParams!.formationOptions.filter(param => param.isActive);
  const teamList = positionParams!.teamOptions.filter(param => param.isActive);
  const contractList = positionParams!.contractTypes;
  const workingHourList = positionParams!.workingHourOptions.filter(param => param.isActive);

  const acceptFormat = 'image/jpeg, image/png, image/jpg';
  
  useEffect(() => {
    dispatch(getPositionParams());
  }, []);

  useEffect(() => {
    if(isEditing){
      setPositionVideo(positionDetail!.videoUrl)
      setFile({ name: positionDetail!.image as string })
      setFormKey(formKey + 1);
    }
  }, [positionDetail]);
  
  const onSubmit = (values: PositionType) => {
    if(file.name === '') {
      ERROR_MODAL('Debes agregar una imagen para esta vacante.');
      return;
    }
    if(isEditing){
      const newPositionDetail = JSON.parse(JSON.stringify(positionDetail));
      const data = {...newPositionDetail, ...values};

      if(data.image === file.name){
        dispatch(updatePosition(data, () => redirectWindow(routes.positions.view)));
      }else{
        const formData = new FormData();
        formData.append('file', file as File, file.name);
        dispatch(addPositionImage(formData, data, isEditing));
      }
    } else {
      const formData = new FormData();
      formData.append('file', file as File, file.name);
      values.active = true;
      values.internal = false;

      dispatch(addPositionImage(formData, values, isEditing));
    }
  };

  const handleExtractVideoId = () => {
    const videoUrl = form.getFieldValue('videoUrl').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    const youtubeId = (videoUrl[2] !== undefined) ? videoUrl[2].split(/[^0-9a-z_-]/i)[0] : videoUrl[0];

    if (youtubeId.length == 11) {
      form.setFieldValue('videoUrl', youtubeId);
      setPositionVideo(youtubeId);
    } else {
      ERROR_MODAL('Este video no es válido.')
    }
  }

  return (
    <Form key={formKey} form={form} layout="vertical" autoComplete="off"
          initialValues={positionDetail ?? {}} onFinish={onSubmit}>
      <Col md={24} xs={24} className={'px-1.5'}>
        <TitleComponent level={4} text={'Multimedia'} className={'mb-1 leading-6 font-medium text-blue-dkt-400'} />
      </Col>

      <Col lg={18} md={24} xs={24} >      
        <Col md={24} xs={24} className={'w-full [&>div]:mb-0'}>
          <Space size={'small'} className={'w-full flex [&>div]:contents mt-2'} direction={'horizontal'}>
            <FormItemComponent name={'videoUrl'} label={'Video'} className={'w-full'} required={false} child={
              <InputComponent />
            } />
            <ButtonComponent text={''} icon={<ReloadOutlined />}
                            className={'bg-orange-dkt-400 hover:bg-orange-dkt-500 focus:outline-none focus:ring-2 ' +
                              'focus:ring-offset-2 focus:ring-orange-dkt-300 mt-2'}
                            onClick={() => handleExtractVideoId()}/>
          </Space>
        </Col>
        {positionVideo &&
          <Col md={24} xs={24} className={'overflow-hidden pb-72 relative h-0'}>
            <iframe className={'left-0 top-0 h-full w-full absolute'}
                    width={'450'} height={'240'}
                    src={routes.youtube + positionVideo}
                    allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
              />
          </Col>
        }
      </Col>
      
      <Col lg={18} md={24} xs={24} className={'mt-4 mb-8 w-full [&>div]:px-1.5 inline-block [&>div]:inline-block [&>div]:w-full'}>
        <Col md={24} xs={24}>
          <Col className={'ant-form-item-label'}>
            <label className={'ant-form-item-required text-sm font-medium text-grey-dkt-400'}>
              Imagen
            </label>
          </Col>

          <UploadComponent acceptFormat={acceptFormat} onUpload={(file: File) => setFile(file)}
                           supportedFilesText={'Archivos soportados: PNG, JPG, JPEG'} />

          {!isEmpty(file.name) &&
            <Space size={'small'} className={'w-full flex [&>div]:contents mt-2'}>
              <InputComponent readOnly={true} disabled={true} value={file.name} title={file.name}/>
              <ButtonComponent text={''} icon={<DeleteOutlined/>}
                               className={'bg-orange-dkt-400 hover:bg-orange-dkt-500 focus:outline-none focus:ring-2 ' +
                                 'focus:ring-offset-2 focus:ring-orange-dkt-300 '}
                               onClick={() => setFile({name: ''})}/>
            </Space>
          }
        </Col>
      </Col>

      <Col md={24} xs={24} className={'px-1.5'}>
        <TitleComponent level={4} text={'Descripción de la vacante'} className={'mb-1 leading-6 font-medium text-blue-dkt-400'} />
        <p className="m-0 text-md text-grey-dkt-500">
          Alguna descripción de esta sección va aquí.
        </p>
      </Col>

      <Col md={24} xs={24} className={'mt-4 mb-8 w-full [&>div]:px-1.5 inline-block [&>div]:inline-block [&>div]:w-full'}>
        <Col md={24} xs={24}>
          <FormItemComponent name={'title'} label={'Título'} required={true}
                             child={
                               <InputComponent placeHolder={'Título del cargo'} />
                             } />
        </Col>
        <Col md={24} xs={24}>
          <FormItemComponent name={'description'} label={'Tareas a realizar'} required={true}
                             child={<ReactQuill theme="snow" />} />
        </Col>
        <Col md={24} xs={24}>
          <FormItemComponent name={'mandatories'} label={'Perfil del aspirante'} required={true}
                             child={<ReactQuill theme="snow" />} />
        </Col>
        <Col md={24} xs={24}>
          <FormItemComponent name={'experience'} label={'Beneficios'} required={true}
                             child={<ReactQuill theme="snow" />} />
        </Col>
        <Col md={12} xs={24}>
          <FormItemComponent name={'idFormations'} label={'Nivel de estudio'} required={true}
                             child={
                               <SelectComponent placeHolder={'Selecciona una opción'} list={formationList} />
                             } />
        </Col>
      </Col>

      <Col md={24} xs={24} className={'px-1.5'}>
        <TitleComponent level={4} text={'Características de la oferta'} className={'mb-1 leading-6 font-medium text-blue-dkt-400'} />
        <p className="text-md text-grey-dkt-500">
          Alguna descripción de esta sección va aquí.
        </p>
      </Col>

      <Col md={24} xs={24} className={'mt-4 mb-8 w-full [&>div]:px-1.5 inline-block [&>div]:inline-block [&>div]:w-full'}>
        <Col md={12} xs={24}>
          <FormItemComponent name={'idTeam'} label={'Equipo'} required={true}
                             child={
                               <SelectComponent placeHolder={'Selecciona una opción'} list={teamList} />
                             } />
        </Col>
        <Col md={12} xs={24}>
          <FormItemComponent name={'idContract'} label={'Tipo de contrato'} required={true}
                             child={
                               <SelectComponent placeHolder={'Selecciona una opción'} list={contractList} />
                             } />
        </Col>
        <Col md={12} xs={24}>
          <FormItemComponent name={'idWorkingHours'} label={'Horas semanales'} required={true}
                             child={
                               <SelectComponent placeHolder={'Selecciona una opción'} list={workingHourList} />
                             } />
        </Col>
        <Col md={12} xs={24}>
          <FormItemComponent name={'salary'} label={'Salario'} required={true}
                             child={
                               <InputComponent placeHolder={'Ej: 2.000.000/a convenir'}
                                               className={'flex [&>input]:-top-px h-9'} />
                             } />
        </Col>
      </Col>

      <Col md={24} xs={24} className={'text-center'}>
        <ButtonComponent text={isEditing ? 'Editar vacante' : 'Crear vacante'} htmlType={'submit'}
                         className={'w-60 h-9 bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'} />
      </Col>
    </Form>
  );
}

PositionForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  positionDetail: PropTypes.any
};
PositionForm.defaultProps = {
  isEditing: false
};

export default PositionForm;