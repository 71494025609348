import React, {useEffect} from 'react';
import ReactQuill from 'react-quill';
import {Row, Col, Form} from 'antd';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../../store/store';
import {getAboutUsContent, setAboutUsContent} from '../../../store/redux/actions/contentActions';
import * as contentSelectors from '../../../store/redux/selectors/contentSelectors';

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';
import TitleComponent from '../../subComponents/TitleComponent';

// Types
import { AboutUsContentType } from '../../../types/contentTypes';


const AboutUsContent = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const aboutUsContent = useSelector(contentSelectors.aboutUsSelector);

  useEffect(() => {
    dispatch(getAboutUsContent());
  }, []);

  useEffect(() => {
    form.setFieldsValue(aboutUsContent)
  }, [aboutUsContent]);
  
  const onSubmit = (values: AboutUsContentType) => {
    dispatch(setAboutUsContent(values));
  };
  
  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Row gutter={16}>
        <Col md={24} xs={24} className={'mt-4 mb-2'}>
          <TitleComponent level={4} text={'Decathlon'} className={'mb-1 leading-6 font-medium text-blue-dkt-400'} />
        </Col>
        <Col md={24} xs={24}>
          <FormItemComponent name={'aboutUs'} label={'¿Quiénes somos?'} required={true}
                            child={<ReactQuill theme="snow"/>} />
        </Col>
        <Col md={24} xs={24}>
          <FormItemComponent name={'ourMission'} label={'Nuestra misión'} required={true}
                            child={<ReactQuill theme="snow"/>} />
        </Col>
        <Col md={24} xs={24} className={'mt-4 mb-2'}>
          <TitleComponent level={4} text={'En Colombia'} className={'mb-1 leading-6 font-medium text-blue-dkt-400'} />
        </Col>
        <Col md={24} xs={24}>
          <FormItemComponent name={'decathlonInColombia'} label={'Decathlon en Colombia'} required={true}
                            child={<ReactQuill theme="snow"/>} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <FormItemComponent name={'localStores'} label={'Tiendas decathlon'} required={true}
                            child={
                              <InputComponent />
                            } />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <FormItemComponent name={'logisticCenters'} label={'Centros logísticos'} required={true}
                            child={
                              <InputComponent />
                            } />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <FormItemComponent name={'localCollaborators'} label={'Colaboradores'} required={true}
                            child={
                              <InputComponent />
                            } />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <FormItemComponent name={'maleCollaborators'} label={'Colaboradores hombres'} required={true}
                            child={
                              <InputComponent />
                            } />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <FormItemComponent name={'femaleCollaborators'} label={'Colaboradores mujeres'} required={true}
                            child={
                              <InputComponent />
                            } />
        </Col>
        <Col md={24} xs={24} className={'mt-4 mb-2'}>
          <TitleComponent level={4} text={'En el mundo'} className={'mb-1 leading-6 font-medium text-blue-dkt-400'} />
        </Col>
        <Col md={24} xs={24}>
          <FormItemComponent name={'decathlonInTheWorld'} label={'Decathlon en el mundo'} required={true}
                            child={<ReactQuill theme="snow"/>} />
        </Col>
        <Col lg={12} md={24} xs={24}>
          <FormItemComponent name={'ownBrands'} label={'Marcas propias'} required={true}
                            child={
                              <InputComponent />
                            } />
        </Col>
        <Col lg={12} md={24} xs={24}>
          <FormItemComponent name={'countries'} label={'Países'} required={true}
                            child={
                              <InputComponent />
                            } />
        </Col>
        <Col lg={12} md={24} xs={24}>
          <FormItemComponent name={'worldStores'} label={'Tiendas decathlon'} required={true}
                            child={
                              <InputComponent />
                            } />
        </Col>
        <Col lg={12} md={24} xs={24}>
          <FormItemComponent name={'warehouses'} label={'Almacenes'} required={true}
                            child={
                              <InputComponent />
                            } />
        </Col>
        <Col lg={12} md={24} xs={24}>
          <FormItemComponent name={'worldCollaborators'} label={'Colaboradores'} required={true}
                            child={
                              <InputComponent />
                            } />
        </Col>
        <Col md={24} xs={24} className={'mt-4 mb-2'}>
          <TitleComponent level={4} text={'Valores'} className={'mb-1 leading-6 font-medium text-blue-dkt-400'} />
        </Col>
        <Col lg={12} md={24} xs={24}>
          <FormItemComponent name={'generosity'} label={'Generosidad'} required={true}
                            child={
                              <InputComponent type={'textarea'} />
                            } />
        </Col>
        <Col lg={12} md={24} xs={24}>
          <FormItemComponent name={'vitality'} label={'Vitalidad'} required={true}
                            child={
                              <InputComponent type={'textarea'} />
                            } />
        </Col>
        <Col lg={12} md={24} xs={24}>
          <FormItemComponent name={'responsibility'} label={'Responsabilidad'} required={true}
                            child={
                              <InputComponent type={'textarea'} />
                            } />
        </Col>
        <Col lg={12} md={24} xs={24}>
          <FormItemComponent name={'authenticity'} label={'Autenticidad'} required={true}
                            child={
                              <InputComponent type={'textarea'} />
                            } />
        </Col>
        <Col md={24} xs={24} className={'text-center mt-4'}>
          <ButtonComponent text={'Guardar'} htmlType={'submit'}
                          className={'w-60 h-9 bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                            'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'} />
        </Col>
      </Row>
    </Form>
  );
}

export default AboutUsContent;