import React, {FunctionComponent, useEffect} from 'react';
import {Row, Col, List, Tooltip} from 'antd';
import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';

// Store
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../../../store/store';
import {getAllReferrals, getReferredById} from '../../../store/redux/actions/settingActions';
import * as settingSelectors from '../../../store/redux/selectors/settingSelectors';

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';

// Functions
import {screenSizeValidator} from '../../../utils/functions';


type ReferralsType = {
  onToggleModal: (visible: boolean, isEditing: boolean, section: string, title: string) => void;
};
const Referrals: FunctionComponent<ReferralsType> = (
  {
    onToggleModal
  }) => {
  const dispatch = useAppDispatch();
  const referrals = useSelector(settingSelectors.allReferralsSelector);
  const isSmallScreen = screenSizeValidator('(max-width: 930px)');

  useEffect(() => {
    dispatch(getAllReferrals());
  }, []);

  const handleEditModal = (referredId: string) => {
    dispatch(getReferredById(referredId));
    onToggleModal(true, true, 'referrals', 'Convenio');
  }

  return (
    <Row>
      <Col md={24} xs={24} className={'text-right mb-4'}>
        <ButtonComponent text={'Nuevo convenio'}
                         icon={<PlusOutlined />}
                         className={'w-fit bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                         onClick={() => onToggleModal(true, false, 'referrals', 'Convenio')} />
      </Col>

      <Col md={24} xs={24}>
        <List itemLayout={'horizontal'} dataSource={referrals}
              grid={{ gutter: 16, column: isSmallScreen ? 1 : 2 }}
              renderItem={item => (
                <List.Item key={item.idReferred}>
                  <Col md={24} xs={24} className={'flex'}>
                    <Col md={20} xs={12}>
                      <p className={'font-medium'}>{item.name}</p>
                      {item.isPrincipal &&
                        <p className={'font-light'}>Principal</p>
                      }
                    </Col>
                    <Col md={4} xs={12} className={'text-right flex'}>
                      <Tooltip title={'Editar'}>
                        <EditOutlined className={'my-auto ml-auto mr-0 text-blue-dkt-400'}
                                      onClick={() => handleEditModal(item.idReferred!)} />
                      </Tooltip>
                    </Col>
                  </Col>
                </List.Item>
              )}
        />
      </Col>
    </Row>
  );
}

Referrals.propTypes = {
  onToggleModal: PropTypes.func.isRequired,
};
Referrals.defaultProps = {
  onToggleModal: () => {
    return null;
  }
};
export default Referrals;