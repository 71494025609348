// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import {Col, Empty, Row, Space} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';

// Store
import {useAppDispatch} from '../../../../store/store';
import * as  applicationsSelector from '../../../../store/redux/selectors/applicationSelectors';
import { downloadApplicantFile } from '../../../../store/redux/actions/applicantActions';

// Components and Subcomponents
import InputComponent from '../../../subComponents/InputComponent';
import ButtonComponent from '../../../subComponents/ButtonComponent';
import TitleComponent from '../../../subComponents/TitleComponent';


const Documents = () => {
  const dispatch = useAppDispatch();
  const applicationDetail = useSelector(applicationsSelector.applicationsDetailSelector);
  const files = applicationDetail.files;

  const handleDownloadFile = (fileName: string) => {
    dispatch(downloadApplicantFile(fileName));
  }

  return (
    <Col className={'mb-2 bg-white p-4 rounded'}>
      <Row>
        <Col md={24} xs={24}>
          <TitleComponent level={5} text={'Documentos:'} className={'text-sm font-medium text-grey-dkt-400'} />
        </Col>
        {files.length > 0 ?
          files.map(file =>
            <Space key={file.idFilesApplicant} size={'small'} className={'w-full mb-2 flex [&>div]:contents'}>
              <InputComponent readOnly={true} disabled={true} value={file.name ?? file.urlFile}
                              title={file.name ?? file.urlFile} />
              <ButtonComponent text={'Descargar'} icon={<DownloadOutlined />} 
                                onClick={() => handleDownloadFile(file.urlFile)}
                                className={'bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                  'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'} />
            </Space>
          )
        :
          <Empty description={'Sin documentos'}/>
        }
      </Row>
    </Col>
  );
}

export default Documents;