import React, {FunctionComponent, useEffect, useState} from 'react';
import {Row, Modal, Col, Form, Checkbox, Switch} from 'antd';
import PropTypes from 'prop-types';
import {DisconnectOutlined} from '@ant-design/icons';

// Store
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../../../store/store';
import * as positionSelectors from '../../../store/redux/selectors/positionSelectors';
import * as subscriptionSelectors from '../../../store/redux/selectors/subscriptionSelectors';
import * as generalSelectors from '../../../store/redux/selectors/generalSelectors';
import {createAll, deleteSubscription, getAllByPositionId} from '../../../store/redux/actions/subscriptionActions';

// Types
import {SubscriptionType} from '../../../types/subscriptionTypes';

// Components and Subcomponents
import ButtonComponent from '../../subComponents/ButtonComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';


type AddEditSubscriptionModalType = {
  isEditing: boolean;
  positionId: string;
  onlySubscriptions: boolean;
  onToggleModal: (visible: boolean, isEditing: boolean, positionId: string) => void;
};
const AddEditSubscriptionModal: FunctionComponent<AddEditSubscriptionModalType> = (
  {
    isEditing, positionId, onlySubscriptions, onToggleModal
  }) => {
  const [anySport, setAnySport] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [initialValues, setInitialValues] = useState({});
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const positionList = useSelector(positionSelectors.allPositionsSelector);
  const positionParams = useSelector(positionSelectors.positionParamsSelector);
  const subscriptionList = useSelector(subscriptionSelectors.allSubscriptionsSelector);
  const isLoading = useSelector(generalSelectors.isLoadingSelector);
  const positionTitle = positionList?.find(position => position.idPosition === positionId)!.title;

  useEffect(() => {
    if(isEditing){
      dispatch(getAllByPositionId(positionId));
    }
  }, []);

  useEffect(() => {
    if(isEditing && subscriptionList!.length > 0){
      const sportsIds = subscriptionList!.map(subscription => subscription.idSport ?? '0');
      const anySport = sportsIds.filter(id => id === '0').length > 0;
      setAnySport(anySport);
      setInitialValues({
        idPositions: positionId,
        isActive: subscriptionList ? subscriptionList[0].status : false,
        sportIds: sportsIds,
      });
      setModalKey(modalKey + 1);
    }
  }, [subscriptionList]);

  const handleUnsubscribe = () => {
    dispatch(deleteSubscription(positionId, onlySubscriptions));
    onToggleModal(false, false, '');
  };

  const handleSubmit = (values: any) => {
    let subscribedSports: SubscriptionType[] = [];
    values.sportIds.map((id: string) => {
      if (id === '0') subscribedSports = [];

      subscribedSports.push({
        idPositions: positionId,
        idSport: id === '0' ? null : id,
        status: isEditing ? values.isActive : true,
      });
    });

    dispatch(createAll(subscribedSports));
    onToggleModal(false, false, '');
  };

  return (
    <Modal title={`${isEditing ? 'Editar suscripción' : 'Suscribirme'}: ${positionTitle}`} visible={!isLoading} closable={false}
           maskClosable={false} keyboard={false} key={modalKey}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cancelar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false, false, '')} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Suscribirme'}`}
                              className={'w-32 h-8 bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick={() => form.submit()} />
           ]}>
      <Form form={form} layout={'vertical'} autoComplete={'off'} onFinish={handleSubmit} initialValues={initialValues}>
        <Row gutter={[8, 8]}>
          {isEditing &&
            <FormItemComponent name={'isActive'} required={false} valuePropName={'checked'}
                               child={<Switch checkedChildren={'Suscripción activa'}
                                              unCheckedChildren={'Suscripción inactiva'}
                                              className={'w-fit'} />}/>
          }

          <Col md={24} xs={24}>
            <FormItemComponent name={'sportIds'} label={'Deportes'} required={true}
                               child={
                                 <Checkbox.Group>
                                   <Row gutter={[8, 8]}>
                                     <Col span={24} className={'mb-2'}>
                                       <Checkbox value={'0'} onChange={e => setAnySport(e.target.checked)}>
                                         Cualquier deporte
                                       </Checkbox>
                                     </Col>
                                     {!anySport && positionParams?.sportOptions.map(option =>
                                         <Col span={8} key={option.id}>
                                           <Checkbox value={option.id}>
                                             {option.value}
                                           </Checkbox>
                                         </Col>
                                     )}
                                   </Row>
                                 </Checkbox.Group>
                               } />
          </Col>

          {isEditing &&
            <Col md={24} xs={24}>
              <ButtonComponent text={'Desuscribirme'} icon={<DisconnectOutlined />}
                               className={'bg-orange-dkt-400 text-sm hover:bg-orange-dkt-500 focus:outline-none focus:ring-2 ' +
                                 'focus:ring-offset-2 focus:ring-orange-dkt-300 after:content-none'}
                               onClick={handleUnsubscribe} />
            </Col>
          }
        </Row>
      </Form>
    </Modal>
  );
}

AddEditSubscriptionModal.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  positionId: PropTypes.string.isRequired,
  onlySubscriptions: PropTypes.bool.isRequired,
  onToggleModal: PropTypes.func.isRequired, 
};
AddEditSubscriptionModal.defaultProps = {
  isEditing: false,
  positionId: '',
  onlySubscriptions: false,
  onToggleModal: () => {
    return null;
  }
};
export default AddEditSubscriptionModal;