import React, {FunctionComponent, useEffect, useState} from 'react';
import {Row, Modal, Col, Form, Switch, Space, Image} from 'antd';
import PropTypes from 'prop-types';
import {DeleteOutlined, ReloadOutlined} from '@ant-design/icons';

// Store
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../../../store/store';
import {
  addCategoryImage, createFormation, createPlace, createReferred, createTeam, createWorkingHour, createLanguage,
  createSport, updateCategory, updateFormation, updatePlace, updateReferred, updateTeam, updateWorkingHour,
  updateLanguage, updateSport, updateEvent, createEvent
} from '../../../store/redux/actions/settingActions';
import * as generalSelectors from '../../../store/redux/selectors/generalSelectors';
import * as settingSelectors from '../../../store/redux/selectors/settingSelectors';

// Types
import {CategoryType, SettingType} from '../../../types/settingsTypes';

// Components and Subcomponents
import ButtonComponent from '../../subComponents/ButtonComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';
import SelectComponent from '../../subComponents/SelectComponent';
import UploadComponent from '../../subComponents/UploadComponent';
import {ERROR_MODAL} from '../../subComponents/responseModals';

// Functions and Constants
import {isEmpty} from '../../../utils/functions';
import baseURL from '../../../api/baseURL';
import { routes } from '../../../utils/routes';


type AddEditSettingModalType = {
  isEditing: boolean;
  title: string;
  section: string;
  onToggleModal: (visible: boolean) => void;
};
const AddEditSettingModal: FunctionComponent<AddEditSettingModalType> = (
  {
    isEditing, title, section, onToggleModal
  }) => {
  const [categoryFile, setCategoryFile] = useState({ name: '' });
  const [categoryVideo, setCategoryVideo] = useState('');
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const isLoading = useSelector(generalSelectors.isLoadingSelector);
  const settingDetail = useSelector(settingSelectors.settingDetailSelector);
  const categoryOptions = useSelector(settingSelectors.teamParamsSelector)?.categoryOptions;
  const eventOptions = useSelector(settingSelectors.eventParamsSelector)?.eventOptions;
  const acceptFormat = 'image/jpeg, image/png, image/jpg';
  const formData = new FormData();

  useEffect(() => {
    if(isEditing && section === 'categories'){
      const { imageUrl, videoUrl  } = settingDetail as CategoryType;
      setCategoryVideo(videoUrl as string)
      setCategoryFile({ name: imageUrl as string });
    }
  }, [settingDetail]);

  const handleSubmit = (values: SettingType) => {
    if(!isEditing){
      values.isActive = true;
    }

    switch (section){
      case 'categories':
        if(categoryFile.name === '') {
          ERROR_MODAL('Debes agregar una imagen para esta categoría.');
          return;
        }
        if(categoryVideo === '') {
          ERROR_MODAL('Debes agregar un video para esta categoría.');
          return;
        }
        if(isEditing) {
          const { imageUrl } = settingDetail as CategoryType;
          if(imageUrl === categoryFile.name) {
            dispatch(updateCategory(values));
          } else {
            formData.append('file', categoryFile as File, categoryFile.name);
            dispatch(addCategoryImage(formData, values, isEditing));
          }
        } else {
          formData.append('file', categoryFile as File, categoryFile.name);
          dispatch(addCategoryImage(formData, values, isEditing));
        }
        break;
      case 'places':
        isEditing ? dispatch(updatePlace(values)) : dispatch(createPlace(values));
        break;
      case 'teams':
        isEditing ? dispatch(updateTeam(values)) : dispatch(createTeam(values));
        break;
      case 'workingHours':
        isEditing ? dispatch(updateWorkingHour(values)) : dispatch(createWorkingHour(values));
        break;
      case 'formations':
        isEditing ? dispatch(updateFormation(values)) : dispatch(createFormation(values));
        break;
      case 'languages':
        isEditing ? dispatch(updateLanguage(values)) : dispatch(createLanguage(values));
        break;
      case 'referrals':
        isEditing ? dispatch(updateReferred(values)) : dispatch(createReferred(values));
        break;
      case 'sports':
        isEditing ? dispatch(updateSport(values)) : dispatch(createSport(values));
        break;
      case 'events':
        isEditing ? dispatch(updateEvent(values)) : dispatch(createEvent(values));
        break;
      default:
        break;
    }
    onToggleModal(false);
  };

  const handleSetCategoryFile = (file?: File) => {
    if(file){
      form.setFieldValue('imageUrl', file.name);
      setCategoryFile(file);
    }else{
      form.setFieldValue('imageUrl', null);
      setCategoryFile({ name: '' });
    }
  };

  const handleExtractVideoId = () => {
    const videoUrl = form.getFieldValue('videoUrl').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    const youtubeId = (videoUrl[2] !== undefined) ? videoUrl[2].split(/[^0-9a-z_-]/i)[0] : videoUrl[0];

    if (youtubeId.length == 11) {
      form.setFieldValue('videoUrl', youtubeId);
      setCategoryVideo(youtubeId);
    } else {
      ERROR_MODAL('Este video no es válido.')
    }
  }

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} ${title}`} visible={!isLoading} closable={false} maskClosable={false} keyboard={false}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cancelar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`} htmlType={'submit'}
                              className={'w-32 h-8 bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick={() => form.submit()} />
           ]}>
      <Form form={form} layout={'vertical'} autoComplete={'off'} onFinish={handleSubmit}
            initialValues={isEditing ? settingDetail : {}}>
        <Row gutter={[8,8]}>
          {isEditing &&
            <>
              {section === 'categories' && <FormItemComponent name={'idCategory'} required={false} child={<></>} />}
              {section === 'places' && <FormItemComponent name={'idPlace'} required={false} child={<></>} />}
              {section === 'teams' && <FormItemComponent name={'idTeam'} required={false} child={<></>} />}
              {section === 'workingHours' && <FormItemComponent name={'idWorkingHour'} required={false} child={<></>} />}
              {section === 'formations' && <FormItemComponent name={'idFormation'} required={false} child={<></>} />}
              {section === 'languages' && <FormItemComponent name={'idLanguage'} required={false} child={<></>} />}
              {section === 'referrals' && <FormItemComponent name={'idReferred'} required={false} child={<></>} />}
              {section === 'sports' && <FormItemComponent name={'idSport'} required={false} child={<></>} />}
              {section === 'events' && <FormItemComponent name={'idEvent'} required={false} child={<></>} />}

              {(section !== 'categories' && section !== 'referrals') &&
                <FormItemComponent name={'isActive'} required={false} valuePropName={'checked'}
                                   child={<Switch checkedChildren={'Activo'} unCheckedChildren={'Inactivo'}
                                                  className={'w-28'}/>}/>
              }
            </>
          }
          {section === 'referrals' &&
            <FormItemComponent name={'isPrincipal'} required={false} valuePropName={'checked'}
                                child={<Switch checkedChildren={'Principal'} unCheckedChildren={'Secundario'}
                                              className={'w-28'}/>}/>
          }
          {section === 'categories' &&
            <>
              <Col md={24} xs={24} className={'w-full [&>div]:mb-0'}>
                <Space size={'small'} className={'w-full flex [&>div]:contents mt-2'} direction={'horizontal'}>
                  <FormItemComponent name={'videoUrl'} label={'Video'} required={true} className={'w-full'} child={
                    <InputComponent />
                  } />
                  <ButtonComponent text={''} icon={<ReloadOutlined />}
                                  className={'bg-orange-dkt-400 hover:bg-orange-dkt-500 focus:outline-none focus:ring-2 ' +
                                    'focus:ring-offset-2 focus:ring-orange-dkt-300 mt-2'}
                                  onClick={() => handleExtractVideoId()}/>
                </Space>
              </Col>
              {categoryVideo &&
                <Col md={24} xs={24} className={'overflow-hidden pb-56 relative h-0'}>
                  <iframe className={'left-0 top-0 h-full w-full absolute'}
                          width={'450'} height={'240'}
                          src={routes.youtube + categoryVideo}
                          allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
                    />
                </Col>
              }
              <FormItemComponent name={'imageUrl'} label={'Imagen'} required={true} className={'w-full mt-4'} 
                child={
                  <>
                    {isEditing && categoryFile.name === (settingDetail as CategoryType).imageUrl &&
                      <Col md={24} xs={24} className={'w-full'}>
                        <Image width={100} src={baseURL + categoryFile.name} />
                      </Col>
                    }
                    <Col md={24} xs={24} className={'w-full'}>
                      <UploadComponent acceptFormat={acceptFormat} onUpload={(file) => handleSetCategoryFile(file)}
                                       supportedFilesText={'Archivos soportados: PNG, JPG, JPEG'} />
                      {!isEmpty(categoryFile.name) &&
                        <Space size={'small'} className={'w-full flex [&>div]:contents mt-2'} direction={'horizontal'}>
                          <InputComponent readOnly={true} disabled={true} value={categoryFile.name} title={categoryFile.name}/>
                          <ButtonComponent text={''} icon={<DeleteOutlined/>}
                                           className={'bg-orange-dkt-400 hover:bg-orange-dkt-500 focus:outline-none focus:ring-2 ' +
                                            'focus:ring-offset-2 focus:ring-orange-dkt-300 '}
                                           onClick={() => handleSetCategoryFile()}/>
                        </Space>
                      }
                    </Col>
                  </>
                } 
              />
              <Col md={24} xs={24} className={'w-full [&>div]:mb-0'}>     
                  <FormItemComponent name={'priority'} label={'Prioridad'} required={true} className={'w-full'} child={
                    <InputComponent type={'number'} />
                  } />
              </Col>
            </>
          }
          {section === 'events' &&
            <Col md={24} xs={24}>
              <FormItemComponent name={'eventType'} label={'Tipo de evento'} required={true}
                                 child={<SelectComponent list={eventOptions!} placeHolder={'Selecciona una opción'} />} />
            </Col>
          }

          <Col md={24} xs={24}>
            <FormItemComponent name={'name'} label={'Nombre'} required={true} child={<InputComponent />} />
          </Col>

          {section === 'places' &&
            <>
              <Col md={12} xs={24}>
                <FormItemComponent name={'latitude'} label={'Latitud'} required={true} child={<InputComponent />} />
              </Col>
              <Col md={12} xs={24}>
                <FormItemComponent name={'longitude'} label={'Longitud'} required={true} child={<InputComponent />} />
              </Col>
            </>
          }
          {(section === 'categories' || section === 'events') &&
            <Col md={24} xs={24}>
              <FormItemComponent name={'description'} label={'Descripción'} required={true}
                                 child={<InputComponent type={'textarea'} />} />
            </Col>
          }
          {section === 'events' &&
            <Col md={24} xs={24}>
              <FormItemComponent name={'message'} label={'Plantilla del mensaje'} required={true}
                                 child={<InputComponent type={'textarea'} />} />
            </Col>
          }
          {section === 'teams' &&
            <Col md={24} xs={24}>
              <FormItemComponent name={'idCategory'} label={'Categoría'} required={true}
                                 child={<SelectComponent list={categoryOptions!} placeHolder={'Selecciona una opción'} />} />
            </Col>
          }
        </Row>
      </Form>
    </Modal>
  );
}

AddEditSettingModal.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  onToggleModal: PropTypes.func.isRequired,
};
AddEditSettingModal.defaultProps = {
  isEditing: false,
  title: '',
  section: '',
  onToggleModal: () => {
    return null;
  }
};
export default AddEditSettingModal;